body {
  margin: 0;
  font-family: 'Roboto', Verdana, Geneva, Tahoma, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fff8f8;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

#root {
  padding-top: 6rem;
}

/*----------------NAVBAR---------------*/
.navbar-custom {
  background-color: #DD5041;
  box-shadow: 0 2px 4px 0 rgba(195, 136, 136, 0.421), 0 5px 15px 0 rgba(97, 37, 37, 0.327);
  font-size: 1.5rem;
  padding: 0.2rem 1%;
}

.navbar-logo {
  height: 3.4rem;
}
.navbar-textLogo{
  text-decoration: none;
  color: rgb(255, 255, 255);
  transition: all 0.5s;
}
.navbar-textLogo:hover{
  text-decoration: none;
  color: white;
  
  transform: scale(1.05);
}
.nav-item {
  margin: 0 0.5rem;
}

.nav-link {
  position: relative;
  color: #e0dbdb;
  text-decoration: none;
  cursor: default;
  pointer-events: none;
}

.nav-link:after {
  display: none;
}

.nav-link:hover {
  color: white;
}

.nav-link:focus {
  color: white;
}

.dropdown-menu {
  background-color: #DD5041;
  border: none;
  font-size: 30px;
}

.dropdown-item {
  color: #ffffff ;
}

.size-1-2{
  font-size: 1.2rem !important;
}
.size-1-4{
  font-size: 1.4rem !important;
}
.size-1-6{
  font-size: 1.6rem !important;
}
.size-1-8{
  font-size: 1.8rem !important;
}
.size-2{
  font-size: 2rem !important;
}
.size-2-2{
  font-size: 2.2rem !important;
}

.sectionContainerCustome{
  background-color:rgba(83, 83, 83, 0.164);
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.541);

  border-radius:8px;
  
}
.marginCustomeTitleCollapse{
  margin: 0px -12px;
  margin-bottom: 0.6rem;
}
.secctionColapsedBtn{
  background-color: rgba(30, 30, 30, 0.274);
  border-radius:0px;
  border-top-left-radius:8px ;
  border-top-right-radius:8px ;
}
.secctionColapsedBtn:hover{
  background-color: rgba(56, 56, 56, 0.454);

}

.secctionBtnNoColapsed{
  background-color: rgba(190, 190, 190, 0.276);
  border-radius:8px;
}

.secctionViweAttendance {
  border-radius: 8px;
  overflow-x: auto; /* Muestra un scrollbar horizontal */
}




@media (min-width: 768px) {

  /* Estilos por defecto del scrollbar */
.secctionViweAttendance::-webkit-scrollbar {
  height: 0.8rem;
}

.secctionViweAttendance::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.2); /* Color del thumb del scrollbar por defecto */
  border-radius: 3px; /* Bordes redondeados del thumb por defecto */
}


/* Estilos al pasar el mouse sobre el scrollbar */
.secctionViweAttendance::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.578); /* Color del thumb del scrollbar al pasar el mouse */
}

}


.error-container{
  height: 5rem; /* Ajusta la altura según tus necesidades */
  margin-top: -4rem; /* Ajusta el margen inferior según tus necesidades */
}
.dropdown-item:focus {
  background-color: #DD5041;
  color: #e0dbdb;
}
.b-blue{
  background-color: #0d00ff!important;
}
.b-red{
  background-color: #ff7300!important;
}
.b-green{
  background-color: #00ff22!important;
  color: red !important;
}

.dropdown-item:hover {
  background-color: #DD5041;
  color: white;
}
.dropdown-item {
  text-align: center;
  position: relative; /* Necesario para posicionar el seudoelemento ::after */
  text-decoration: none;
  background-color: transparent !important;
  color: rgb(255, 255, 255); /* Cambia el color del texto a tu preferencia */
}

/* Pseudoelemento ::after para la línea que se extiende hacia los costados */
.dropdown-item::after {
  content: "";
  position: absolute;
  bottom: -2px; /* La mitad del grosor del borde inferior */
  left: 50%;
  width: 0;
  height: 4px; /* Grosor de la línea */
  background-color: rgb(255, 255, 255); /* Color de la línea */
  transition: width 0.5s ease, left 0.5s ease; /* Transición suave para la animación */
}

/* Estilos para el elemento activo */
.dropdown-item.active {
  color: white; /* Cambia el color del texto a tu preferencia cuando el elemento está activo */
}

/* Estilos para la extensión hacia los costados cuando el elemento está activo */
.dropdown-item.active::after {
  width: 100%; /* Ancho completo de la extensión hacia los costados */
  left: 0;
}

.dropdown:hover .dropdown-menu {
  display: block;
}

.navbar-toggler:focus {
  box-shadow: none;
}

/*-------------------INDEX---------------*/

.dashboard {
  background-color: #F7E3E1;
  padding: 25px;
  border-radius: 5px;
  box-shadow: 0px 10px 15px rgba(0, 0, 0, 1);
}

.fa-thumbtack {
  transform: rotate(30deg);
}

.input-field input {
  width: 80%;
  height: 100%;
  outline: none;
  border-radius: 3px;
  border: 1px solid #ccc;
  font-size: 20px;
  padding-left: 15px;
}

.input-field button {
  width: 35px;
  height: 100%;
  border: none;
  color: #DD5041;
  background-color: white;
  margin-left: 5px;
  font-size: 20px;
  outline: none;
  cursor: pointer;
  border-radius: 3px;
  transition: all 0.3s ease;
}

.todo-list {
  padding-left: 0;
}

.todo-list li {
  position: relative;
  list-style: none;
  height: 45px;
  line-height: 45px;
  margin-bottom: 8px;
  background: white;
  border-radius: 3px;
  padding: 0 15px;
}

.todo-list li span {
  position: absolute;
  right: 0;
  width: 45px;
  background-color: #DD5041;
  color: white;
  text-align: center;
}

.dashboard-footer button {
  padding: 6px 10px;
  border-radius: 3px;
  border: none;
  outline: none;
  color: #fff;
  font-weight: 400;
  font-size: 16px;
  margin-left: 5px;
  background: #DD5041;
  cursor: pointer;
  user-select: none;
}

/*--------CRUDS-------*/
.actions button {
  margin-bottom: 0.5rem;
  margin-right: 0.5rem;
}

.table-content {
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.301);
  background-color: #ffffff;
  border-radius: 10px;
  margin-bottom: 30px;
}
.transparent-table >:not(caption)>*>* {
  background-color: transparent !important;
}

.btn:active, .btn:focus {
  box-shadow: none;
  outline: none;
}


.viewFilter {
  margin: 0 5px;
  margin-bottom: 0 !important;
}

.rowDisabled {
  color: #838383 !important;
  font-style: italic;
  
}

.rowDisabled td {
  color: #787878b3 !important;
  font-style: italic;
}

.rowFullDisabled td {
  color: #ff6969bd !important;
  font-style: italic;
}

.rowNew td {
  color: #000000bd !important;
  background-color: #aaffb5;
}


.user {
  color: white !important;
  font-size: 1.2rem ;
}

.user img {
  border-radius: 50%;
  width: 50px;
  margin: 0px 15px 0px 10px
}
.alertCustom{
  font-size: 0.8rem;
  
}
.user p {
  margin-bottom: 0px;
  font-weight: bold;
}

.td-attendance {
  text-align: center;
  vertical-align: middle;
}

.no-margin {
  margin-bottom: 0 !important;
}

.inline-grid {
  display: inline-grid;
}

.inline-grid .btn {
  margin-block-start: 20px;
}

/*ATTENDANCE*/
input[type="checkbox"]:checked {
  background-color: #DD5041;
  border: none;
}


.skeleton:hover {
  background-color: "#ffffff"
}

.bodyPricingCases {
  margin: 10px;
}

.padrepricingCasesActive-custom {
  background-color: #F7B2B2;
  border-radius: 10px;
  margin-bottom: 2vh;
}

.pricingCasesActive-custom {
  display: grid;
  grid-template-columns: repeat(2, 3fr);
  grid-gap: 10px;
}

.pricingCasesActive-custom a {
  text-decoration: none !important;
  color: inherit;
}

.pricingCasesActive-custom {
  display: flex;
  flex-direction: column;
}

.accordion-button:not(.collapsed) {
  color: #000000;
  background-color: #c7002f71;
  box-shadow: inset 0 -1px 0 rgb(0 0 0 / 13%);
}

.accordion-button{
  width: 100% ;
}


@media screen and (max-width: 960px) {
  .error-container{
    height: 5rem; /* Ajusta la altura según tus necesidades */
    margin-top: -2rem; /* Ajusta el margen inferior según tus necesidades */
  }
 
  
}
@media screen and (max-width: 460px) {
  .table-responsive >:not(caption)>*>* {
    padding: 0.3rem ;
  }
}

@media screen and (max-width: 420px) {
  .table-responsive >:not(caption)>*>* {
    padding: 0.2rem ;
  }
  .actions{
    flex-direction: column;
  }
}

